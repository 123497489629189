<template>
    <div>
     <el-button icon="el-icon-s-operation" slot="left" class="m-icon" @click="nav=true"></el-button>
   <div class="m-navbar" v-if="nav" @click="nav=false"> </div>
  <transition name="nav">
        <div class="m-navba" v-if="nav">
            <dl>
                <dd>
                    <router-link to="/robot/" >
                        <i @click="nav=false"></i>
                        <span @click="nav=false">首页</span>
                    </router-link>
                </dd>
                <dd>
                    <router-link to="/sensar/">
                        <i @click="nav=false"></i>
                        <span @click="nav=false">关于我们</span>
                    </router-link>
                </dd>
                <dd>
                    <router-link to="/electricMeter/">
                        <i @click="nav=false"></i>
                        <span @click="nav=false">产品介绍</span>
                    </router-link>
                </dd>
                <dd>
                    <router-link to="/switch/">
                        <i @click="nav=false"></i>
                        <span @click="nav=false">联系我们</span>
                    </router-link>
                </dd>
            </dl>            
        </div>
     </transition>
    </div>
</template>
 
<script>
 export default {
     name:'TipsWindown',
    data() {
        return {
            nav: false,
        }
    },
     methods: {
     }
 }
</script>
 
<style scoped lang="scss">
.m-header {
    height: 86px;
    font-size: 16px;
}
  .m-icon {
      background: #26a2ff;
      border: #26a2ff;
      font-size: 16px;
      color: white;
    }
.m-navbar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 2;
}
.m-navba {
    position: absolute;
    left: 0;
    right: 134px;
    top: 0;
    bottom: 0;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
}
    dl {
        color: black;
        dt {
            font-size: 40px;
            background: #D2E4F7;
            height: 120px;
            line-height: 120px;
            font-size: 45px;
            margin-top: -26px;
            span {
                margin-left: 20px;
            }
            i {
                margin-left: 160px;
            }
 
        }
        dd {
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 10px;
            border-radius:16px;
            a {
                text-decoration: none;
                i {
                    margin-left: 20px;
                }
                span {
                    color: #08185E;
                    margin-left: 30px;
                }
            }
        }
        router-link{
            margin-left: 10px;
        }
        dd:nth-child(2n) {
            background: #EFF3F7;
            // opacity: 0.3;
            border: 2px solid #EFF3F7;
        }
        dd:nth-child(2n+1) {
            background: #EFF3F7;
            // opacity: 0.5;
            border: 2px solid #EFF3F7;
        }
    }
    @media only screen and (max-width: 780px){
        .m-icon{
            font-size: 30px;
            margin-top: -20px;
        }
    }
    @media only screen and (max-width: 550px){
        .m-icon{
            font-size: 14px;
            margin-top: -7px;
        }
    }
.nav-leave {
  /* 定义 出场动画的 起始状态 */
  /* 只停留一帧 */
  transform: translateX(0px);
}
.nav-leave-active {
  /* 定义 出场动画 过程 */
  transition: all .4s ease;
}
.nav-leave-to {
  /* 定义 出场动画 结束状态（即：该动画要达到的目标状态） */
  transform: translateX(-600px);
}
/* 定义  入场动画 */
.nav-enter {
  /* 定义 入场动画 的起始状态 */
  transform: translateX(-600px);
 
}
.nav-enter-active {
  /* 定义 入场动画 过程 */
  transition: all .4s ease;
}
.nav-enter-to {
  /* 定义 入场动画 过程 */
  /* 只停留一帧 */
  transform: translateX(0px);
}
</style>