<template>
  <el-carousel direction="horizontal" :autoplay="false">
    <el-carousel-item v-for="item in imges" :key="item">
      <img :src="item.url" alt="">
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
       imges:[
                 {url:require('../../public/img/banner1.jpg')},
                 {url:require('../../public/img/banner2.jpg')},
                 {url:require('../../public/img/banner3.jpg')},
            ]
    }
  },
}
</script>
<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .el-carousel__container{
    height: 600px;
  }
   @media only screen and (max-width: 1080px){
      .el-carousel__container{
       height: 300px;
      }
   }
   @media only screen and (max-width: 830px){
      .el-carousel__container{
       height: 200px;
      }
   }
</style>