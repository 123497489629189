<template>
  <div class="title">
        <p class="titleTop"></p>
        <div class="titleNav">
            <div class="titleLog">
                <img src="../../public/img/log.png" alt="">
            </div>
            <div class="titleRigth">
               <ul>
                   <li class="active_type">首页</li>
                   <li class="active_type">关于我们</li>
                   <li class="active_type">产品介绍</li>
                   <li class="active_type">联系我们</li>
               </ul>
            </div>
        </div>
         <tips-windown class="column"></tips-windown>
        <!-- 轮播图 -->
        <div class="swiper">
            <swpr-code></swpr-code>
        </div>
        <!-- 内容 -->
        <div class="connet">
            <div class="connetNav">
                <div class="textPI">
                    <img src="../../public/img/img1.png" alt="">
                </div>
                <div class="navPI">
                    <div class="headline"></div>
                    <div class="titlePI">天衡专线管理系统</div> 
                    <p>天衡专线管理系统是一个大票零担物流运输平台管理系统，从客户下订单开始至上门提货、开单、短驳运输、干线运输、中转外发、送货上门、
                    签收和回单返回管理整体的业务操作，通过系统全贯通，实现货物流通操作的线上化及运输节点透明化；并且实现了财务应收、应付的对账和核销，
                    以及网点账户结算自动化，最终实现业务财务一体化目标。</p>
                </div>
            </div>
            <div class="connetNav">
                <div class="textPI">
                    <div class="headline"></div>
                    <div class="titlePIo">天玑系统</div> 
                    <p>天玑系统是一个集（客户端、承运商端、管理端、司机端）多端物流供应链平台管理系统，为客户提供下订单转换开单服务、上门提货服务、干线运输服务、
                    送货签收服务、回单及时寄返服务 的纵向全链条货物运输跟踪管理平台；实现了财务管理、应收、应付的对账和核销结算半自动化，系统自动与财务核算系统凭证对接，
                    最终实现业务财务一体化。</p>
                </div>
                <div class="navPI">
                    <img src="../../public/img/img2.png" alt="">
                </div>
            </div>
            <div class="connetNav">
                <div class="textPI">
                    <img src="../../public/img/img3.png" alt="">
                </div>
                <div class="navPI">
                    <div class="headline"></div>
                    <div class="titlePI">天枢网络货运平台系统</div> 
                    <p>天枢网络货运平台系统是一款集运输业务、支付、开票于一身的平台系统，智能抓取运单轨迹，快捷线上支付，快捷线上开票，实现了从货主下单、到支付开票，
                        司机接单到签收上传回单、收款的业务。</p>
                </div>
            </div>
            <div class="connetNav">
                <div class="textPI">
                    <div class="headline"></div>
                    <div class="titlePIo">天璇系统</div> 
                    <p>天璇系统是集（司机端、用户端、管理端）多端物流配送平台管理系统，为客户提供跨系统强偶合、城配集约、大票中转、小票集约承运服务，通过“大平台+小组织”
                        的组织形态来服务体系内线路公司、市场落地业务，以集配分包模式来打造共享终端目标，进而达到线路降本、集团增效、分包赋能、司机创收、中转稳流的效果，
                        最终实现业财线上化的互联互通。</p>
                        <a href="https://beian.miit.gov.cn/" target="_blank" title="粤ICP备19113074号">粤ICP备19113074号</a>
                </div>
                <div class="navPI">
                    <img src="../../public/img/img4.png" alt="">
                </div>
            </div>
        </div>
        <!-- <div style="clear:both"></div> -->
        <p class="titleTop"></p>
         <!-- 底部 -->
        <div class="footer">
            <div class="footerLeft">
                <p>联系我们</p>
                <span>13520381041</span>
                <span>广东深圳市龙岗区平安大道1号</span>
                <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19113074号</a>
            </div>
            <!-- <div class="footerRight">
                <img src="../../public/img/img5.png" alt="">
            </div> -->
        </div>
    </div>
</template>

<script>
import swprCode from './swpr.vue'
import TipsWindown from './TipsWindown.vue'
export default {
  name: 'HelloWorld',
  components:{
      swprCode,
      TipsWindown
  },
//   props: {
//     msg: String
//   }
}
</script>

<style scoped>
body{
    margin: 0;
    padding: 0;
}
a{text-decoration:none;color:#2b2b2b;}
li{list-style: none;}
i{
    font-style: normal;
}
.title{
    width: 100%;
}
.column{
    /* position: relative; */
    /* right: 25px;
    margin-top: 8px; */
    display: none;
}
li.active_type:hover{
    color: #05ca05;
}
.titleTop {
    width: 100%;
    height: 20px;
    background-color: #409eff;
    background-image: linear-gradient(to right, #409eff, #05ca05);
    margin: 0;
  }
  .titleNav{
      display: flex;
      justify-content: space-between;
      height: 80px;
      margin-top: 10px;
  }
  .titleLog{
      width: 200px;
      height: 80px;
      margin-left: 200px;
  }
  img{
      width: 100%;
      height: 100%;
  }
  .titleRigth{
      width: 60%;
  }
  li{
      display: inline-block;
      margin: 6px 80px;

  }
  .active_type {
    cursor:pointer;
    font-weight: 700;
  }
  .swiper {
    width: 100%;
    height: 600px;
}  
  .typeId{
    color: #11c138;
  }
  .connet{
      width: 70%;
      margin: 0 auto;
      margin-top: 80px;
  }
  .connetNav{
      margin-bottom: 125px;
      overflow:hidden;
  }
    .textPI{
    width: 50%;
    float: left;
  }
  .textPI img{
      width: 100%;
      height: 100%;
  }
  .navPI{
      float: right;
      width: 46%;
  }
  .connet p{
      line-height: 2.5;
      color: #858585;
      font-weight: 500;
  }
   .titlePI{
      text-align: right;
      margin-right: 10px;
      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 700;
  }
   .navPI .headline{
       width: 40px;
       height: 6px;
       margin-bottom: 10px;
       background-color: #409eff;
       border-radius: 5px;
       margin-left: 91%;
   }
  .headline{
    width: 40px;
    height: 6px;
    margin-bottom: 10px;
    background-color: #409eff;
    border-radius: 5px;
}
  .titlePIo{
    text-align: left;
      margin-right: 10px;
      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 700;
  }
  .titlePI p{
      line-height: 50px;
  }

  .footer{
    padding: 50px 15%;
    height: 200px;
    background-color: #2b2b2b;
  }
  .footerLeft{
      float: left;
      width: 50%;
  }
  .footerLeft p{
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 20px;
  }
  .footerLeft span{
      display: block;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
  }
  .footerLeft a{
      display: block;
      font-size: 20px;
      font-weight: 500;
      text-decoration: underline;
      color: #409eff;
  }
  .footerRight{
      float: right;
      width: 10%;
  }
  .footerRight img{
      width: 140px;
      height: 140px;
  }

  @media only screen and (max-width: 1565px){
    .titleLog{
        margin-left: 100px;
    }
    .titleRigth {
        width: 65%;
    }
    .titleRigth li{
        margin:  6px 60px;
    }
    .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden{
        height: 10%;
    }
    /* .headline{
        margin-left: 90%;
    } */
    .connet p {
        line-height: 2;
    }
    .footerRight {
        width: 13%;
    }
  }
  @media only screen and (max-width: 1400px){
    /* .headline{
        margin-left: 88%;
    } */
    .titlePIo{
        margin-bottom: 0;
    }
    .connet p {
        line-height: 1.5;
    }
    .footerRight {
        width: 15%;
    }
  }

  @media only screen and (max-width: 1200px){
    .titleRigth li{
        margin:  6px 50px;
    }
    .connetNav{
        margin-bottom: 50px;
    }
    .textPI{
        float: none;
        width: 100%;
        margin: 0 auto;
    }
    .navPI,
    .navPI img{
        width: 100%;
        margin: 0 auto;
    }
    .titlePI{
        margin-top: 10px;
        text-align: left;
    }
     .headline{
        display: none;
    }
    .footerRight {
        width: 18%;
    }
  }

  @media only screen and (max-width: 1080px){
    .titleLog{
        margin-left: 30px;
    }
    .titleRigth li{
        margin: 6px 40px;
    } 
    .footerRight {
        width: 20%;
    }
    .swiper{
        height: 300px;
    }
    .tab{
        display: none;
    }
  }

  @media only screen and (max-width: 960px){
    .titleRigth li{
        margin: 6px 30px;
    } 
    .footerRight {
        width: 23%;
    }
    .titlePI,
    .titlePIo{
        font-size: 18px;
        margin-bottom: 16px;
    }
    .connetNav {
        margin-bottom: 34px;
    }
    .connet{
        margin-top: 50px;
    }
    .column{
       display: block;
       margin-top: -8%;
       margin-left: 80%;
       margin-bottom: 10px;
    }
    .titleRigth{
        display: none;
    }
  }

  @media only screen and (max-width: 830px){
    .titleRigth li{
        margin: 6px 20px;
    }
    .footerRight {
        width: 28%;
    }
    .swiper{
        height: 220px;
    }
    .column{
        margin-left: 88%;
    }
  }

  @media only screen and (max-width: 750px){
      .titleNav{
          height: 50px;
      }
        .titleLog img,
        .titleLog{
            width: 150px;
            height: 50px;
        }
        .titleRigth {
            width: 75%;
        }
        .titleRigth li {
            margin: 0px 15px;
        }
        .footerLeft p{
            font-size: 18px;
        }
        .footerLeft span{
            font-size: 16px;
        }
        .footerRight img{
            width: 110px;
            height: 110px;
        }
        .swiper{
            height: 180px;
        }
  }

  @media only screen and (max-width: 630px){
    .titleRigth{
        line-height: 18px;
    }
    .footerRight{
        width: 33%;
    }
    .titleRigth li {
        margin: 0px 10px;
    }
    .footer{
        height: 100px;
    }
    .footerLeft p{
        font-size: 14px;
    }
    .footerLeft span{
        font-size: 12px;
    }
    .footerRight img {
        width: 80px;
        height: 80px;
    }
    .titlePI,
    .titlePIo{
        font-size: 16px;
        margin-bottom: 10px;
    }
    .connetNav {
        margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 570px){
    .titleRigth li{
        margin: 0 4px;
        font-size: 16px;
    }
    .titleLog img, .titleLog{
        width: 90px;
        height: 30px;
    }
    .titleRigth{
        line-height: 0px;
    }
    .titlePI,
    .titlePIo{
        font-size: 14px;
    }
    .connet{
        margin-top: 30px;
    }
  }
  @media only screen and (max-width: 430px){
    .titleNav{
        height: 40px;
    }
    .titleLog img, .titleLog {
        width: 76px;
        height: 24px;
        margin-top: 5px;
    }
    .column{
        margin-left: 80%;
    }
  }
  @media only screen and (max-width: 330px){
      .titleNav{
          height: 30px;
      }
  }
</style>
